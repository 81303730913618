
  import ZPin from './z-pin.vue'

  export default {
    props: {
      pins: { type: Array},
      module: { type: String, default: 'catalogue' },
      infinite: { type: Boolean, default: true },
      countryId: { type: Number }
    },
    components: {
      ZPin,
    },
    computed: {
      allDataLoaded() {
        return this.$store.state[this.module].allDataLoaded
      }
    },
    methods: {
      async openPin(pin) {
        this.$router.push(`/app/pin-detail/${pin.id}`)
      },
      async loadData(ev) {
        if ( this.countryId ) {
          await this.$store.dispatch(`${this.module}/fetchByPage`, { country: this.countryId })
        } else {
          await this.$store.dispatch(`${this.module}/fetchByPage`)
        }
        ev.target.complete()
      },
    },
  }
