<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <h3
          slot="start"
          class="ion-no-margin ion-margin-start"
        >Filter</h3>
        <ion-buttons slot="end">
          <ion-button icon-only @click="closeModal">
            <ion-icon :icon="closeOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-no-padding" v-if="!_isEmpty(filterMetadata)">
      <h3 class="ion-padding-start ion-margin-bottom">Year of production</h3>
      <ion-item class="ion-no-padding">
        <ion-grid class="ion-no-padding ion-padding-start">
          <ion-row class="ion-padding-bottom">
            <ion-col class="ion-padding-end ion-justify-content-between ion-align-items-center"
                     @click="openYearSinceSelectionModal">
              <div>
                <ion-label>
                  From
                  <div class="ion-margin-top">{{ filter.since || 'Any' }}</div>
                </ion-label>
              </div>
              <ion-icon :icon="caretDownOutline"></ion-icon>
            </ion-col>
            <ion-col class="ion-padding-start ion-justify-content-between ion-align-items-center"
                     @click="openYearTillSelectionModal">
              <div>
                <ion-label>
                  To
                  <div class="ion-margin-top">{{ filter.till || 'Now' }}</div>
                </ion-label>
              </div>
              <ion-icon :icon="caretDownOutline"></ion-icon>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>

      <ion-item class="ion-no-padding" button :detail-icon="caretForwardOutline" @click="openEventSelectionModal">
        <div class="ion-padding-horizontal">
          <ion-label class="ion-padding-vertical">
            Purpose of issue
            <div class="ion-margin-top">{{ filter.event?.title || 'All' }}</div>
          </ion-label>
        </div>
      </ion-item>
      <ion-item class="ion-no-padding" button :detail-icon="caretForwardOutline" @click="openCountrySelectionModal">
        <div class="ion-padding-horizontal">
          <ion-label class="ion-padding-vertical">
            Country
            <div class="ion-margin-top">{{ filter.country?.title || 'All' }}</div>
          </ion-label>
        </div>
      </ion-item>
      <ion-item class="ion-no-padding">
        <ion-label class="ion-padding-start ion-padding-vertical ion-no-margin">Official</ion-label>
        <ion-checkbox :checked="filter.official" @ion-change="filter.official = $event.detail.checked"></ion-checkbox>
      </ion-item>

      <div class="ion-margin ion-padding-vertical">
        <ion-button class="--default" @click="closeModal">Show me <span v-if="totalPinsByFilter"> &nbsp;({{ totalPinsByFilter }})&nbsp; </span> pins</ion-button>
        <ion-button fill="clear" expand="full" size="small" @click="onRemoveAllFilters">Remove all filters</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
  import wAxios from '@/plugins/w/axios'
  import { defineComponent } from 'vue'
  import { closeOutline, caretForwardOutline, caretDownOutline } from 'ionicons/icons'
  import { modalController, pickerController } from '@ionic/vue'
  import SelectionModal from '@/plugins/app/_components/z-selection-modal.vue'

  const api = {
    meta: () => wAxios.get('v1/pins/meta')
  }

  export default defineComponent({
    props: {
      module: { type: String, default: 'catalogue' },
    },

    mounted() {
      this._loadFilterMetadata()
    },

    data() {
      return {
        filterMetadata: {},
        closeOutline,
        caretForwardOutline,
        caretDownOutline
      }
    },

    computed: {
      totalPinsByFilter() {
        return this.$store.getters[this.module+'/totalPinsByFilter']
      },

      pins() {
        return this.$store.state[this.module].pins
      },

      filter: {
        get() {
          return this.$store.state[this.module].filter
        },
        set(val) {
          this.$store.commit(`${this.module}/filterUpdated`, val)
        }
      },

      years() {
        if (!this.filterMetadata.year) return

        // get list of possible years
        const years = []
        for (let i = this.filterMetadata.year.since; i <= this.filterMetadata.year.till; i++) {
          years.push(i)
        }
        return years
      }
    },

    watch: {
      filter: {
        deep: true,
        handler() {
          // filter results on every filter parameters change
          this.$store.commit(`${this.module}/page`, 1)
          this.$store.commit(`${this.module}/allDataLoadedChanged`, false)
          this.$store.dispatch(`${this.module}/fetchByPage`, 1)
        }
      }
    },

    methods: {
      closeModal() {
        modalController.dismiss({metaFrom: this.filterMetadata.year.since, metaTill: this.filterMetadata.year.till})
      },

      openEventSelectionModal() {
        this._openSelectionModal('Purpose of issue', ['All', ...this.filterMetadata.events], this.filter.event, data => {
          if (data.data) this.filter.event = data.data == 'All' ? '' : data.data
        }, 'All')
      },

      openCountrySelectionModal() {
        this._openSelectionModal('Country', [{title: 'All', id: null}, ...this.filterMetadata.countries], this.filter.country, data => {
          if (data.data) this.filter.country = data.data
        }, 'All')
      },

      openYearSinceSelectionModal() {
        const options = this.years.map(year => { return { id: year, title: year.toString() } })
        this._openSelectionModal('Year since', [{title: 'Any', id: null}, ...options], this.filter.since, data => {
          if (data.data) this.filter.since = data.data.id
        }, 'Any')
      },

      openYearTillSelectionModal() {
        const options = this.years.map(year => { return { id: year, title: year.toString() } })
        this._openSelectionModal('Year till', [{title: 'Now', id: null}, ...options], this.filter.till, data => {
          if (data.data) this.filter.till = data.data.id
        }, 'Now')
      },

      onRemoveAllFilters() {
        this.filter = {
          official: false,
          event: null,
          country: null,
          since: null,
          till: null
        }
      },

      async _openSelectionModal(title, list, selectedItem, completionCallback, firstTitle) {
        const selectionModal = await modalController.create({
          component: SelectionModal,
          componentProps: { list, title, selectedItem, firstTitle },
          cssClass: 'modal-fullheight'
        })
        selectionModal.onDidDismiss().then(completionCallback)
        return selectionModal.present()
      },

      async _loadFilterMetadata() {
        try {
          const { filters } = await api.meta()
          this.filterMetadata = filters

        } catch (err) {
          console.error(err)
        }
      },

      _isEmpty(obj) {
        return Object.keys(obj).length == 0
      }
    }
  })
</script>

<style lang="sass" scoped>
h3
  font-weight: 700

ion-grid
  ion-col
    display: flex

    & > div
      flex-grow: 1

  ion-col:first-child
    border-right: 1px solid var(--ion-color-medium)

ion-content
  --padding-top: 20px
</style>
