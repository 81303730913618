import App from './App.vue'
import { createApp } from 'vue'
import { isPlatform } from '@ionic/vue'



// VUE ZOOMER FOR IMAGE GALLERY
import VueZoomer from 'vue-zoomer'


// PLUGINS
import '@/custom.ts'


// DEFAULT STYLES
import '@/theme'


import router from './router'
import store from './store'

// IONIC COMPONENTS
import {
    IonicVue,
    IonPage,
    IonContent,
    IonFooter,
    IonButton,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonInput,
    IonButtons,
    IonIcon,
    IonImg,
    IonInfiniteScrollContent,
    IonInfiniteScroll,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonGrid,
    IonText,
    IonCheckbox,
    IonSearchbar, IonRouterOutlet
} from '@ionic/vue'


import eventBus from 'vue3-eventbus'


const app = createApp(App)
    .use(IonicVue, { mode: 'ios' })
    .use(router)
    .use(store)
    .use(VueZoomer)
    .use(eventBus)

// GOOGLE ANALYTICS
import VueGtag from "vue-gtag-next"
import { trackRouter } from 'vue-gtag-next'

if (isPlatform('desktop')) {
    app.use(VueGtag, {
        property: {
            id: "UA-68936011-3"
        }
    });
    trackRouter(router)
}


app.component('ion-page', IonPage)
app.component('ion-content', IonContent)
app.component('ion-footer', IonFooter)
app.component('ion-button', IonButton)
app.component('ion-buttons', IonButtons)
app.component('ion-header', IonHeader)
app.component('ion-input', IonInput)
app.component('ion-checkbox', IonCheckbox)
app.component('ion-title', IonTitle)
app.component('ion-toolbar', IonToolbar)
app.component('ion-icon', IonIcon)
app.component('ion-text', IonText)
app.component('ion-img', IonImg)
app.component('ion-grid', IonGrid)
app.component('ion-row', IonRow)
app.component('ion-col', IonCol)
app.component('ion-item', IonItem)
app.component('ion-label', IonLabel)
app.component('ion-searchbar', IonSearchbar)
app.component('ion-infinite-scroll-content', IonInfiniteScrollContent)
app.component('ion-infinite-scroll', IonInfiniteScroll)
app.component('ion-router-outlet', IonRouterOutlet)


// CUSTOM COMPONENTS
import ZCard from './plugins/app/_components/z-card.vue'
import ZPinList from '@/plugins/app/_components/z-pin-list.vue'
import ZFilter from '@/plugins/app/filter/z-filter.vue'

app.component('z-card', ZCard)
app.component('z-pin-list', ZPinList)
app.component('z-filter', ZFilter)


router.isReady().then(() => {
  app.mount('#app')
})
