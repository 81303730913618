
import { defineComponent } from 'vue'
import { modalController } from '@ionic/vue'
import { arrowBack } from 'ionicons/icons'
import {
    IonRadio,
    IonRadioGroup
  } from '@ionic/vue'

export default defineComponent({
  components: {
    IonRadio,
    IonRadioGroup
  },

  props: {
    list: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    firstTitle: {
      type: String,
      required: true
    },
    selectedItem: {
      type: [Object, String]
    }
  },

  data() {
    return {
      query: '',
      arrowBack
    }
  },

  computed: {
    filteredList() {
      let list = this.list.filter(item => (item.title || item).toLowerCase().includes(this.query.toLowerCase())).filter(item => item.id)
      list = list.sort((a,b) => a.title < b.title ? -1 : 1 )
      list.unshift({title: this.firstTitle, id: null})
      return list
    }
  },

  methods: {
    onSelect(item) {
      modalController.dismiss(item)
    },

    closeModal() {
      modalController.dismiss()
    }
  },
})
