import wAxios from '@/plugins/w/axios'

export default {
  namespaced: true,
  state() {
    return {
      pins: [],
      totalPinsCount: 0,
      totalPinsByFilter: 0,
      page: 1,
      allDataLoaded: false,
      isLoading: false,
      filter: {
        search: '',
        official: false,
        event: null,
        country: null,
        since: null,
        till: null
      },
    }
  },
  getters: {
    totalPinsCount(state) {
      return state.totalPinsCount
    },
    totalPinsByFilter(state) {
      return state.totalPinsByFilter
    },
    pins(state) {
      return state.pins
    },
    isLoading(state) {
      return state.isLoading
    },
    allDataLoaded(state) {
      return state.allDataLoaded
    },
    filters(state) {
      return {
        ...state.filter,
        country: state.filter.country?.id,
        event: state.filter.event?.id,
        official: state.filter.official ? 1 : null,
        'year[since]': state.filter.since,
        'year[till]': state.filter.till
      }
    }
  },
  mutations: {
    fetched(state, pins) {
      state.pins = pins
    },
    fetchedByPage(state, pins) {
      state.pins = state.pins.concat(pins)
    },
    pageIncremented(state) {
      state.page = ++state.page
    },
    page(state, page) {
      state.page = page
    },
    allDataLoadedChanged(state, data) {
      state.allDataLoaded = data
    },
    loadingChanged(state, status) {
      state.isLoading = status
    },
    filterUpdated(state, filterData) {
      state.filter = { ...this.state.filter, ...filterData }
    },
    filterSearchUpdated(state, search) {
      state.filter.search = search
    },
    totalPinsCount(state, data) {
      state.totalPinsCount = data
    },
    totalPinsByFilter(state, data) {
      state.totalPinsByFilter = data
    },
    inCollectionChanged(state, data) {
      let pin = state.pins.find(pin => pin.id == data.pinId)
      if (pin) {
        pin['in_collection'] = data.value
      }
    }
  },

  actions: {
    async fetchPins({ getters, commit }) {
      try {
        const data = await wAxios.get_auth('v1/pins', {
          params: getters.filters
        })
        commit('fetched', data)
      } catch (err) {
        console.log(err)
      }
    },
    async fetchByPage({ getters, commit, state }, page?) {
      commit('loadingChanged', true)
      if ( page ) {
        commit('page', page)
        commit('fetched', [])
        commit('allDataLoadedChanged', false)
      }
      try {
        const data = await wAxios.get_auth_all(`v1/pins?page=${state.page}&per_page=40`, {
          params: getters.filters
        }).then(response => {
          if (!state.totalPinsCount) {
            commit('totalPinsCount', response.data.meta.total)
          }

          commit('totalPinsByFilter', response.data.meta.total)
          return response.data.data
        })

        commit('fetchedByPage', data)
        commit('pageIncremented')
        commit('loadingChanged', false)
        if (data.length == 0) {
              commit('allDataLoadedChanged', true)
          }
      } catch (err) {
          console.log(err)
      }
   },
  },

}
