<template>
  <div class="container">
    <ion-grid>
      <ion-row>
        <ion-col class="ion-no-padding" size="4" size-md="2" v-for="pin in pins" :key="pin.id">
          <div class="top"></div>
          <z-pin
              @click="openPin(pin)"
              :pin="pin"
              class="box"
              :module="module"
              :key="pin.id">
          </z-pin>
        </ion-col>
      </ion-row>
    </ion-grid>


    <ion-infinite-scroll
        @ionInfinite="loadData($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="allDataLoaded"
        v-if="infinite"
    >
      <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Loading more data...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</template>

<script lang="ts">
  import ZPin from './z-pin.vue'

  export default {
    props: {
      pins: { type: Array},
      module: { type: String, default: 'catalogue' },
      infinite: { type: Boolean, default: true },
      countryId: { type: Number }
    },
    components: {
      ZPin,
    },
    computed: {
      allDataLoaded() {
        return this.$store.state[this.module].allDataLoaded
      }
    },
    methods: {
      async openPin(pin) {
        this.$router.push(`/app/pin-detail/${pin.id}`)
      },
      async loadData(ev) {
        if ( this.countryId ) {
          await this.$store.dispatch(`${this.module}/fetchByPage`, { country: this.countryId })
        } else {
          await this.$store.dispatch(`${this.module}/fetchByPage`)
        }
        ev.target.complete()
      },
    },
  }
</script>

<style lang="sass" scoped>
.container
  padding-bottom: 70px
ion-col
  padding-top: 5px
  padding-bottom: 20px
  &:first-child .box
    border-left: none
  &:last-child .box
    border-right: none

.box
  box-sizing: border-box
  padding: 10px
  position: relative
  height: 100%
  border-right: 1px solid rgba(154, 154, 154, 0.4)


.top
  margin: 5px auto
  width: 80%
  position: relative
  top: -9px
  border-top: 1px solid rgba(154, 154, 154, 0.4)

@media only screen and (max-width: 767px)
  ion-col:nth-child(1) .top,
  ion-col:nth-child(2) .top,
  ion-col:nth-child(3) .top
    border-top: none


  ion-col:nth-child(3n) .box
    border-right: none
  ion-col:nth-child(3n + 1) .box
    border-left: none

@media only screen and (min-width: 768px)
  ion-col:nth-child(6n) .box
    border-right: none
  ion-col:nth-child(6n + 1) .box
    border-left: none

  ion-col:nth-child(1) .top,
  ion-col:nth-child(2) .top,
  ion-col:nth-child(3) .top,
  ion-col:nth-child(4) .top,
  ion-col:nth-child(5) .top,
  ion-col:nth-child(6) .top
    border-top: none
</style>
