
  import {modalController} from '@ionic/vue'
  import Filter from '@/plugins/app/filter/a-filter-modal.vue'

  export default {
    props: {
      module: { type: String, default: 'catalogue' },
      title: { type: String, default: 'Search' }
    },
    emits: ['filterActived'],
    data() {
      return {
        filter: '',
        metaFrom: null,
        metaTill: null,
        activeFilter: false
      }
    },

    watch: {
      filter: function () {
        if (this.filter.length == 0) {
          this.$store.commit(`${this.module}/filterSearchUpdated`, '')
          this.$store.dispatch(`${this.module}/fetchByPage`, 1)
          this.$emit('filterActived', {active: false})
        }
        else {
          this.$store.commit(`${this.module}/filterSearchUpdated`, this.filter)
          this.$store.dispatch(`${this.module}/fetchByPage`, 1)
          this.$emit('filterActived', {active: true})
        }
      }
    },

    computed: {
      totalPins() {
        return this.$store.getters[this.module+'/totalPinsCount']
      },
    },

    methods: {
      async openFilter() {
        this.filter = ''
        const modal = await modalController.create({
          component: Filter,
          componentProps: { module: this.module },
          cssClass: 'modal-fullheight'
        })
        modal.onDidDismiss().then(modalData => {
          if (modalData.data) {
            this.metaFrom = modalData.data.metaFrom
            this.metaTill = modalData.data.metaTill
          }
          this.activeFilter = this._isFilterActived()
        })
        return modal.present()
      },

      _isFilterActived() {
        let filter = this.$store.state[this.module].filter
        if (filter.official != false ||
            ( filter.event && filter.event.id ) ||
            ( filter.country && filter.country.id ) ||
            ( filter.since && this.metaFrom && filter.since != this.metaFrom ) ||
            ( filter.till && this.metaTill && filter.till != this.metaTill ) ||
            ( filter.since != null ) ||
            ( filter.till != null ) ) {
          this.$emit('filterActived', {active: true})
          return true
        }
        this.$emit('filterActived', {active: false})
        return false
      }
    }
  }
