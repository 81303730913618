
  import { mapGetters } from 'vuex'

  export default {
    props: ['pin', 'module'],
    computed: {
      ...mapGetters('wAuth', ['isLoggedIn']),
      initials() {
        return this.pin.title.substring(0, 2)
      },
    }
  }
