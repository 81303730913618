<template>
  <ion-app>
    <ion-router-outlet :animated="false" />
  </ion-app>
</template>

<script lang="ts">
  import { IonApp } from '@ionic/vue'
  import { Plugins, StatusBarStyle } from '@capacitor/core'
  const { SplashScreen, StatusBar } = Plugins
  import { isPlatform } from '@ionic/vue'
  import { MobileAccessibility } from "@ionic-native/mobile-accessibility"

  export default {
    components: {
      IonApp,
    },

    created() {
      MobileAccessibility.setTextZoom(100)
      MobileAccessibility.updateTextZoom()
      MobileAccessibility.usePreferredTextZoom(false)

      if (isPlatform('desktop'))
        return

      StatusBar.setStyle({
        style: StatusBarStyle.Light
      })

      StatusBar.setBackgroundColor({
        color: '#ffffff'
      })
      SplashScreen.hide()
    }
  }
</script>

<style lang="sass">
ion-content
  --padding-start: 20px
  --padding-end: 20px
.ion-no-border
  padding: 20px
</style>
